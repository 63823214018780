/*=============================================================================
 Aqua.scss - 

 (C) 2021 SpacetimeQ INC
=============================================================================*/
.NeonText {
  color: #fff;
  /*
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa;
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
  */
  animation: flicker 5s infinite alternate;
}
@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
      text-shadow:
      0 0 4px #fff,
      0 0 11px #fff,
      0 0 19px #fff,
      0 0 40px #0fa,
      0 0 80px #0fa;
      // 0 0 90px #0fa,
      // 0 0 100px #0fa,
      // 0 0 150px #0fa;
  }
  20%, 24%, 55% {
    text-shadow: none;
  }
}

@mixin bubble-animation(
  $sec: 1.5s
) {
  animation: bubble-anim $sec ease-out;
}

/**
 * Aqua button generator: Jul.11, 2021 by Soomin K.
 *
 * - default: wide button
 * - rectangle: (0, 92%)
 * - small: (20px, 92%, false)
 * - circle: (50%, 70%, true)
 */
@mixin aquabutton(
  $anim: 0,          // animation seconds
  $bdr-r: 25px,      // border-radius
  $glare-w: 90%,     // glare width
  $shadow-lg: true,  // use large shadow?
  $glare-h: 50%,     // glare height
  $glare-b-op: 0.2,  // glare bottom opacity
  $blur: 0           // glare blur effect (for large object)
) {
  position: relative;
  flex-shrink: 0;
  border-radius: $bdr-r;
  text-shadow: 2px 2px 2px rgba(10, 10, 10, 0.3), -1px -1px 1px rgba(10, 10, 10, 0.3);
  @if ($shadow-lg) {
    box-shadow: inset 0 13px 25px rgba(255,255,255,0.5), 0 3px 5px rgba(0,0,0,0.2), 0 10px 13px rgba(0,0,0,0.1);
  } @else {
    box-shadow: inset 0 6px 12px rgba(255,255,255,0.5), 0 2px 3px rgba(0,0,0,0.2), 0 5px 6px rgba(0,0,0,0.1);
  }
  cursor: pointer;
  transition: all ease .2s;
  &:hover {
    @if ($shadow-lg) {
      box-shadow: inset 0 13px 25px rgba(255,255,255,0.8), 0 3px 5px rgba(0,0,0,0.2), 0 10px 13px rgba(0,0,0,0.2);
    } @else {
      box-shadow: inset 0 6px 12px rgba(255,255,255,0.8), 0 2px 3px rgba(0,0,0,0.2), 0 5px 6px rgba(0,0,0,0.2);
    }
    @if ($anim) {
      @include bubble-animation($anim);
    } @else {
      transform: scale(1.15);
    }
  }
  &:before {
    position: absolute;
    background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,$glare-b-op));
    @if ($blur) {
      filter: blur($blur);
    }
    border-radius: $bdr-r;
    content:'';
    height: $glare-h;
    width: $glare-w;
    left: (100% - $glare-w) / 2;
    top: 1px;
    pointer-events: none;  // prevent blocking x button
    transition: all ease .2s;
  }
}

.Font-Mincho {
  font-family: 'Sawarabi+Mincho';
}

// Aqua Button
.AquaButton     { @include aquabutton(); }
.AquaBig        { @include aquabutton(0, 40px, 98%, true, 40%, 0, 4px); }
.AquaRect       { @include aquabutton(0, 0, 98%); }
.AquaSmall      { @include aquabutton(0, 12px, 92%, false); }
.AquaCircle     { @include aquabutton(0, 50%, 70%, false); }
.AquaCircleBig  { @include aquabutton(0, 50%, 70%, true, 50%, 0.3, 9px); }
// with animation version
$anim: 1.5s;
.AquaButtonA    { @include aquabutton($anim); }
.AquaBigA       { @include aquabutton($anim, 40px, 98%, true, 40%, 0, 4px); }
.AquaRectA      { @include aquabutton($anim, 0, 98%); }
.AquaSmallA     { @include aquabutton($anim, 12px, 92%, false); }
.AquaCircleA    { @include aquabutton($anim, 50%, 70%, false); }
.AquaCircleBigA { @include aquabutton($anim, 50%, 70%, true, 50%, 0.3, 9px); }

.AquaBall {
  // display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  background: radial-gradient(circle at bottom, #81e8f6, #76deef 10%, #055194 80%, #062745 100%);
  text-shadow: 1px 1px 4px rgba(10, 10, 10, 0.3);

  &:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: radial-gradient(circle at top, white, rgba(255, 255, 255, 0) 58%);
    filter: blur(5px);
    z-index: 2;
  }
  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 5%;
    left: 10%;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    filter: blur(1px);
    z-index: 2;
    transform: rotateZ(-30deg);
  }
  & .shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 50%);
    transform: rotateX(90deg) translateZ(-160px);
    z-index: 1;
  }
  &.plain {
    background: black;
    &:before, &:after {
      display: none;
    }
  }
  &.bubble {
    background: radial-gradient(circle at 50% 55%,
      rgba(240, 245, 255, 0.9),
      rgba(240, 245, 255, 0.9) 40%,
      rgba(225, 238, 255, 0.8) 60%,
      rgba(43, 130, 255, 0.4));
    // animation: bubble-anim 2s ease-out infinite;
    &:before {
      filter: blur(0);
      height: 80%;
      width: 40%;
      background: radial-gradient(circle at 130% 130%,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0) 46%,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0.8) 58%,
        rgba(255, 255, 255, 0) 60%,
        rgba(255, 255, 255, 0) 100%);
      transform: translateX(131%) translateY(58%) rotateZ(168deg) rotateX(10deg);
    }
    &:after {
      display: block;
      background: radial-gradient(circle at 50% 80%,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0) 74%,
        white 80%,
        white 84%,
        rgba(255, 255, 255, 0) 100%);
    }
  }
  &:hover {
    @include bubble-animation();
  }
  cursor: pointer;
  transition: transform ease-in-out .5s;
  box-shadow: 5px 8px 10px rgba(0,0,0,0.2), 10px 20px 20px rgba(0,0,0,0.1);
}

@keyframes bubble-anim {
  0%  { transform: scale(1); }
  20% { transform: scaleY(0.95) scaleX(1.05); }
  48% { transform: scaleY(1.05) scaleX(0.9); }
  68% { transform: scaleY(0.98) scaleX(1.02); }
  80% { transform: scaleY(1.02) scaleX(0.98); }
  97%, 100% { transform: scale(1); }
}
